import React from "react"

import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "components/layout"
import SEO from "../../components/seo.js"
import GridIcons from "../../components/content/gridicons"

const PartnerPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query PartnerPageQuery {
        site {
          siteMetadata {
            title
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="For Partners" />

          <div className={"page-header"}>
            <div className="container">
              {/* <img src={SlackUVisionImage}/> */}

              {/* <img data-src="holder.js/500x100?theme=gray" /> */}
              <h2>{data.site.siteMetadata.title} for Partner Organizations</h2>
              <p>
                Helping Apps, App Stores, platforms, enterprises, insurance
                &amp; governing bodies
                {/* <br/>  */} be more efficient &amp; build more trusted
                experiences &amp; ecosystems.
              </p>

              {/*   <div className="col-xs-auto">
                            <img src="/images/b2bscreenshot1.png" className="center-block  img-responsive mx-auto" />
                          </div> */}
            </div>
          </div>

          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  <h3>
                    Do you need more data to protect your users from privacy
                    threats?
                  </h3>

                  <p>
                    As the largest, open database of privacy ratings and reports
                    in the world, our business is making high-quality, official
                    privacy data openly available via an API. Privacy and
                    InfoSec Data that can be trusted, accessed, analysed,
                    monitored and reviewed when and how it’s needed.
                  </p>
                </div>
              </div>

              <GridIcons />
            </div>

            <div className="row">
              <div className="col-md-12 text-center pb-4">
                <Link to="/about/why" className="btn btn-primary  btn-sm">
                  Learn More about Why {data.site.siteMetadata.title} Exists?
                </Link>
              </div>
            </div>
          </section>
          <section className="features features-3">
            <div className={"bg-color-dark"}>
              <div className={"container"}>
                <div className={"integration-guid"}>
                  <div className={"title"}>
                    <h2>Our API Helps Many Partners</h2>
                    <br />
                    <br />
                  </div>

                  <div className={"row"}>
                    <div className="col-md-4">
                      <ul>
                        <li>Apps</li>
                        <li>App Stores</li>
                        <li>Social Media</li>
                        <li>Marketplaces</li>
                        <li>Email</li>
                        <li>Texting &amp; SMS</li>
                        <li>Adtech &amp; Networks</li>
                        <li>Operating Systems</li>
                        <li>Browsers</li>
                        <li>SaaS</li>
                        <li>IoT </li>
                        <li>Media </li>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <ul>
                        <li>Cloud Computing</li>
                        <li>Platforms</li>
                        <li>Domain Registrars</li>
                        <li>Hosting &amp; Infrastructure</li>
                        <li>Managed Services </li>
                        {/* <li>Managed Wordpress Hosting</li> */}
                        <li>Telecoms</li>
                        <li>ISPs</li>
                        <li>Cybersecurity</li>
                        <li>IT Consulting </li>
                        <li>GRC</li>
                        <li>Startups</li>
                        <li>Enterprises</li>
                      </ul>
                    </div>

                    <div className="col-md-4">
                      <ul>
                        <li>Internet Domain Authorities</li>
                        <li>Internet Standards Nonprofits</li>
                        <li>Consumer Privacy Non-profits</li>
                        <li>Government Procurement Agencies</li>
                        <li>Law Enforcement Agencies</li>
                        <li>Auditors</li>
                        <li>Regulators</li>
                        <li>Big Data</li>
                        <li>Business Intelligence</li>
                        <li>M&amp;A Firms</li>
                        <li>Venture Capitalists</li>
                        <li>Insurance</li>
                      </ul>
                    </div>
                    {/* <img alt={"News"} src={thumbnailGuid2}/> */}

                    {/* <img data-src="holder.js/754x354?theme=gray" /> */}
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </section>
          {/*       <div className={"bg-color-light"}>
            <div className={"container"}>
              <div className={"integration-guid"}>
                <div className={"title"}>
                  <h2>How to use UVision for Partners</h2>
                </div>

                <div className={"content"}>
                  <h4>Overview</h4>
                  <p>
                    As a partner of UVision, you can use the site for
                    free just like end users can but there's additional features
                    and benefits for your organization.
                  </p>
                  <p>
                    For our partners, API plans give you direct, real-time
                    access to the underlying structured data, with powerful
                    queries and results as JSON or XML, ready to enhance data on
                    demand or power onboarding or investigation workflows.
                  </p>
                  <h4>Step 1</h4>
                  <p>
                    You can also search for any vendor, product, site, app,
                    service, solution or company that's in our database.
                  </p>
                  

                  <h4>Step 2</h4>
                  <p>
                    You can then view the profile of the company in question and
                    relevant information, tools and data at your finger tips.
                  </p>
                  
                  <h4>Step 3</h4>
                  <p>
                    You can also view more in depth business-only and
                    partner-only information if you or your team members create
                    an account and save the profile in your dashboard in your
                    account for future reference and to monitor changes to
                    profiles.
                  </p>
                  <h4>Step 4</h4>
                  <p>
                    As a partner, you can also query our API (application
                    programming interface) to integrate with your product,
                    business operations or other platforms to help protect your
                    users and customers.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
 */}
          <div className={"call-to-action"}>
            <div className={"container"}>
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with{" "}
                  {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                <Link
                  to={data.site.siteMetadata.getStartedUrl}
                  // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )}
  />
)

export default PartnerPage
